<template>
  <div class="switch-menu">
    <v-menu
      :location="['left', 'right'].includes(menuPosition) ? menuPosition : 'bottom'"
      attach
      :disabled="disabled"
    >
      <template #activator="{ props }">
        <deck-button
          v-if="!disabled"
          :text="selectedItem?.title"
          :icon-prepend="selectedItem?.icon"
          :tooltip-props="{
            text: subheader,
            position: 'bottom',
          }"
          :icon-append="$vuetify.display.smAndDown ? null : 'chevron-down'"
          icon-kind="regular"
          :icon="$vuetify.display.smAndDown ? selectedItem?.icon : null"
          color="controls"
          kind="ghost"
          is-ready
          v-bind="props"
        />
      </template>

      <v-list
        :selected="[selectedItem.key]"
        density="compact"
      >
        <v-list-subheader class="pl-4">
          {{ subheader }}
        </v-list-subheader>

        <template
          v-for="item in items"
          :key="item.key"
        >
          <v-divider
            v-if="item.divider"
            :key="`${item.key}-divider`"
            class="mb-2"
          />

          <v-list-item
            :aria-label="item.title"
            :to="item.to"
            :value="item.key"
            v-bind="item.key !== selectedItem?.key ? item.on : {}"
          >
            <template #prepend>
              <v-icon
                size="18"
              >
                {{ item.icon }}
              </v-icon>
            </template>

            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>

            <template #append>
              <v-icon
                v-if="item.key === selectedItem?.key"
                size="14"
                color="grey-darken-1"
              >
                fa-regular fa-check
              </v-icon>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default defineComponent({
  name: 'TheTopbarSwitchMenu',
  props: {
    items: { type: Array, default: () => [], validator: value => value.every(item => item.key && item.title) },
    selectedItem: { type: Object, default: () => ({}) },
    subheader: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    menuPosition: { type: String, default: 'bottom' },
  },
});
</script>
